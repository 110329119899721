export const DanaDormList = [
    {
        name: "Dana 108",
        dorm: "Dana",
        number: "108",
        sqft: 198,
        type: "double"
 
 
    },
    {
        name: "Dana 111",
        dorm: "Dana",
        number: "111",
        sqft: 193,
        type: "double"
 
 
    },
    {
        name: "Dana 112",
        dorm: "Dana",
        number: "112",
        sqft: 677,
        type: "suite (six person)"
 
 
    },
    {
        name: "Dana 113",
        dorm: "Dana",
        number: "113",
        sqft: 212,
        type: "triple"
 
 
    },
    {
        name: "Dana 115",
        dorm: "Dana",
        number: "115",
        sqft: 696,
        type: "suite (five person)"
 
 
    },
    {
        name: "Dana 120",
        dorm: "Dana",
        number: "120",
        sqft: 112,
        type: "single"
 
 
    },
    {
        name: "Dana 121",
        dorm: "Dana",
        number: "121",
        sqft: 112,
        type: "single"
 
 
    },
    {
        name: "Dana 122",
        dorm: "Dana",
        number: "122",
        sqft: 202,
        type: "double"
 
 
    },
    {
        name: "Dana 123",
        dorm: "Dana",
        number: "123",
        sqft: 118,
        type: "single"
 
 
    },
    {
        name: "Dana 124",
        dorm: "Dana",
        number: "124",
        sqft: 211,
        type: "double"
 
    },
    {
        name: "Dana 125",
        dorm: "Dana",
        number: "125",
        sqft: 187,
        type: "double"

    },
    {
        name: "Dana 127",
        dorm: "Dana",
        number: "127",
        sqft: 186,
        type: "double"

    },
    {
        name: "Dana 128",
        dorm: "Dana",
        number: "128",
        sqft: 211,
        type: "double"

    },
    {
        name: "Dana 129",
        dorm: "Dana",
        number: "129",
        sqft: 187,
        type: "double"

    },
    {
        name: "Dana 130",
        dorm: "Dana",
        number: "130",
        sqft: 204,
        type: "double"

    },
    {
        name: "Dana 131",
        dorm: "Dana",
        number: "131",
        sqft: 187,
        type: "double"

    },
    {
        name: "Dana 132",
        dorm: "Dana",
        number: "132",
        sqft: 203,
        type: "double"

    },
    {
        name: "Dana 133",
        dorm: "Dana",
        number: "133",
        sqft: 118,
        type: "single"

    },
    {
        name: "Dana 134",
        dorm: "Dana",
        number: "134",
        sqft: 110,
        type: "single"

    },
    {
        name: "Dana 135",
        dorm: "Dana",
        number: "135",
        sqft: 109,
        type: "single"

    },
    {
        name: "Dana 142",
        dorm: "Dana",
        number: "142",
        sqft: 684,
        type: "suite (six person)"

    },
    {
        name: "Dana 145",
        dorm: "Dana",
        number: "155",
        sqft: 692,
        type: "suite (five person)"

    },
    {
        name: "Dana 148",
        dorm: "Dana",
        number: "148",
        sqft: 201,
        type: "double"

    },
    {
        name: "Dana 149",
        dorm: "Dana",
        number: "149",
        sqft: 212,
        type: "triple"

    },
    {
        name: "Dana 151",
        dorm: "Dana",
        number: "151",
        sqft: 188,
        type: "double"

    },
    {
        name: "Dana 208",
        dorm: "Dana",
        number: "208",
        sqft: 118,
        type: "single"

    },
    {
        name: "Dana 210",
        dorm: "Dana",
        number: "210",
        sqft: 210,
        type: "double"

    },
    {
        name: "Dana 211",
        dorm: "Dana",
        number: "211",
        sqft: 191,
        type: "double"

    },
    {
        name: "Dana 212",
        dorm: "Dana",
        number: "212",
        sqft: 667,
        type: "suite (six person)"

    },
    {
        name: "Dana 213",
        dorm: "Dana",
        number: "213",
        sqft: 212,
        type: "triple"

    },
    {
        name: "Dana 215",
        dorm: "Dana",
        number: "215",
        sqft: 699,
        type: "single"

    },
    {
        name: "Dana 220",
        dorm: "Dana",
        number: "220",
        sqft: 111,
        type: "single"

    },
    {
        name: "Dana 221",
        dorm: "Dana",
        number: "221",
        sqft: 110,
        type: "single"

        
    },
    {
        name: "Dana 222",
        dorm: "Dana",
        number: "222",
        sqft: 202,
        type: "double"

    },
    {
        name: "Dana 223",
        dorm: "Dana",
        number: "223",
        sqft: 118,
        type: "single"

    },
    {
        name: "Dana 224",
        dorm: "Dana",
        number: "224",
        sqft: 211,
        type: "double"

    },
    {
        name: "Dana 225",
        dorm: "Dana",
        number: "225",
        sqft: 187,
        type: "double"

    },
    {
        name: "Dana 225",
        dorm: "Dana",
        number: "225",
        sqft: 187,
        type: "double"

    },
    {
        name: "Dana 227",
        dorm: "Dana",
        number: "227",
        sqft: 186,
        type: "double"

    },
    {
        name: "Dana 228",
        dorm: "Dana",
        number: "228",
        sqft: 211,
        type: "double"

    },
    {
        name: "Dana 229",
        dorm: "Dana",
        number: "229",
        sqft: 187,
        type: "double"

    },
    {
        name: "Dana 230",
        dorm: "Dana",
        number: "230",
        sqft: 204,
        type: "double"

    },
    {
        name: "Dana 231",
        dorm: "Dana",
        number: "231",
        sqft: 187,
        type: "double"

    },
    {
        name: "Dana 232",
        dorm: "Dana",
        number: "232",
        sqft: 203,
        type: "double"

    },
    {
        name: "Dana 233",
        dorm: "Dana",
        number: "233",
        sqft: 118,
        type: "single"

    },
    {
        name: "Dana 234",
        dorm: "Dana",
        number: "234",
        sqft: 111,
        type: "single"

    },
    {
        name: "Dana 235",
        dorm: "Dana",
        number: "235",
        sqft: 111,
        type: "single"

    },
    {
        name: "Dana 242",
        dorm: "Dana",
        number: "242",
        sqft: 670,
        type: "suite (six person)"

    },
    {
        name: "Dana 245",
        dorm: "Dana",
        number: "245",
        sqft: 698,
        type: "suite (five person)"

    },
    {
        name: "Dana 246",
        dorm: "Dana",
        number: "246",
        sqft: 208,
        type: "double"

    },
    {
        name: "Dana 248",
        dorm: "Dana",
        number: "248",
        sqft: 124,
        type: "single"

    },
    {
        name: "Dana 249",
        dorm: "Dana",
        number: "249",
        sqft: 212,
        type: "triple"

    },
    {
        name: "Dana 251",
        dorm: "Dana",
        number: "251",
        sqft: 191,
        type: "double"

    },
    {
        name: "Dana 308",
        dorm: "Dana",
        number: "308",
        sqft: 123,
        type: "single"

    },
    {
        name: "Dana 310",
        dorm: "Dana",
        number: "310",
        sqft: 210,
        type: "double"

    },
    {
        name: "Dana 311",
        dorm: "Dana",
        number: "311",
        sqft: 196,
        type: "double"
 
 
    },
    {
        name: "Dana 312",
        dorm: "Dana",
        number: "312",
        sqft: 681,
        type: "suite (six person)"

    },
    {
        name: "Dana 313",
        dorm: "Dana",
        number: "313",
        sqft: 212,
        type: "triple"

    },
    {
        name: "Dana 315",
        dorm: "Dana",
        number: "315",
        sqft: 692,
        type: "suite (five person)"

    },
    {
        name: "Dana 320",
        dorm: "Dana",
        number: "320",
        sqft: 108,
        type: "single"

    },
    {
        name: "Dana 321",
        dorm: "Dana",
        number: "321",
        sqft: 109,
        type: "single"

    },
    {
        name: "Dana 322",
        dorm: "Dana",
        number: "322",
        sqft: 202,
        type: "double"

    },
    {
        name: "Dana 323",
        dorm: "Dana",
        number: "323",
        sqft: 118,
        type: "single"

    },
    {
        name: "Dana 324",
        dorm: "Dana",
        number: "324",
        sqft: 211,
        type: "double"

    },
    {
        name: "Dana 325",
        dorm: "Dana",
        number: "325",
        sqft: 187,
        type: "double"

    },
    {
        name: "Dana 327",
        dorm: "Dana",
        number: "327",
        sqft: 186,
        type: "double"

    },
    {
        name: "Dana 328",
        dorm: "Dana",
        number: "328",
        sqft: 212,
        type: "double"

    },
    {
        name: "Dana 329",
        dorm: "Dana",
        number: "329",
        sqft: 187,
        type: "double"

    },
    {
        name: "Dana 330",
        dorm: "Dana",
        number: "330",
        sqft: 202,
        type: "double"

    },
    {
        name: "Dana 331",
        dorm: "Dana",
        number: "331",
        sqft: 187,
        type: "double"

    },
    {
        name: "Dana 332",
        dorm: "Dana",
        number: "332",
        sqft: 203,
        type: "double"

    },
    {
        name: "Dana 333",
        dorm: "Dana",
        number: "333",
        sqft: 118,
        type: "single"

    },
    {
        name: "Dana 334",
        dorm: "Dana",
        number: "334",
        sqft: 113,
        type: "single"

    },
    {
        name: "Dana 335",
        dorm: "Dana",
        number: "335",
        sqft: 111,
        type: "single"

    },
    {
        name: "Dana 342",
        dorm: "Dana",
        number: "342",
        sqft: 679,
        type: "suite (six person)"

    },
    {
        name: "Dana 345",
        dorm: "Dana",
        number: "345",
        sqft: 690,
        type: "suite (five person)"

    },
    {
        name: "Dana 346",
        dorm: "Dana",
        number: "346",
        sqft: 208,
        type: "double"

    },
    {
        name: "Dana 348",
        dorm: "Dana",
        number: "348",
        sqft: 124,
        type: "single"

    },
    {
        name: "Dana 349",
        dorm: "Dana",
        number: "349",
        sqft: 212,
        type: "triple"

    },
    {
        name: "Dana 351",
        dorm: "Dana",
        number: "351",
        sqft: 196,
        type: "double"

    },

    

    

]