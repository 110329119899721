import React from 'react';
import House from '../media/logo.png';
import {Link} from 'react-router-dom';
import '../styles/Navbar.css';

function Navbar() {
  return (
    <div className = "navbar">

      
    <div className = 'leftSide'>
      <Link to="/">
        <img src={House} />
      </Link>
    </div>
      

        <div className = "right">
            <Link to="/"> Home </Link>
            <Link to="/dorms"> Dorms </Link>
            <Link to="/review"> Review </Link>
            <Link to="/about"> About </Link>

        </div>
    </div>
  )
}

export default Navbar
