import React from 'react'
import { Link } from 'react-router-dom';

function DormRoom({name, dorm, number, sqft, type}) {
  return (
    <div className = "dormRoom">
      <h1> {name} </h1>
      <p> {type} </p>
      <p> sq. ft. : {sqft} </p>
      <Link to= {"/dorms/" + dorm + "/" + number}>
        <button> View Room Information </button>
      </Link>

    </div>
  )
}

export default DormRoom
