import React from 'react'

export const FDormList = [
    
    {
        name: "Foss 109",
        dorm: "Foss",
        number: "109",
        sqft: 814,
        type: "suite (five-person) "

    },
    {
        name: "Foss 113",
        dorm: "Foss",
        number: "113",
        sqft: 387,
        type: "quad"

    },
    {
        name: "Foss 116",
        dorm: "Foss",
        number: "116",
        sqft: 182,
        type: "double"

    },
    {
        name: "Foss 151",
        dorm: "Foss",
        number: "151",
        sqft: 112,
        type: "single"

    },
    {
        name: "Foss 152",
        dorm: "Foss",
        number: "152",
        sqft: 191,
        type: "double"

    },
    {
        name: "Foss 153",
        dorm: "Foss",
        number: "153",
        sqft: 113,
        type: "single"

    },
    {
        name: "Foss 154",
        dorm: "Foss",
        number: "154",
        sqft: 142,
        type: "single"

    },
    {
        name: "Foss 160",
        dorm: "Foss",
        number: "160",
        sqft: 146,
        type: "double"

    },
    {
        name: "Foss 162",
        dorm: "Foss",
        number: "162",
        sqft: 205,
        type: "double"

    },
    {
        name: "Foss 164",
        dorm: "Foss",
        number: "164",
        sqft: 195,
        type: "double"

    },
    {
        name: "Foss 165",
        dorm: "Foss",
        number: "165",
        sqft: 181,
        type: "double"

    },
    {
        name: "Foss 166",
        dorm: "Foss",
        number: "166",
        sqft: 207,
        type: "double"

    },
    {
        name: "Foss 167",
        dorm: "Foss",
        number: "167",
        sqft: 117,
        type: "single"

    },
    {
        name: "Foss 168",
        dorm: "Foss",
        number: "168",
        sqft: 146,
        type: "single"

    },
    {
        name: "Foss 170",
        dorm: "Foss",
        number: "170",
        sqft: 211,
        type: "double"

    },
    {
        name: "Foss 172",
        dorm: "Foss",
        number: "172",
        sqft: 114,
        type: "single"

    },
    {
        name: "Foss 174",
        dorm: "Foss",
        number: "174",
        sqft: 571,
        type: "suite (triple)"

    },
    {
        name: "Foss 176",
        dorm: "Foss",
        number: "176",
        sqft: 197,
        type: "double"

    },
    {
        name: "Foss 206",
        dorm: "Foss",
        number: "206",
        sqft: 557,
        type: "suite (quad)"

    },
    {
        name: "Foss 207",
        dorm: "Foss",
        number: "207",
        sqft: 531,
        type: "suite (quad)"

    },
    {
        name: "Foss 209",
        dorm: "Foss",
        number: "209",
        sqft: 120,
        type: "single"

    },
    {
        name: "Foss 210",
        dorm: "Foss",
        number: "210",
        sqft: 121,
        type: "single"

    },
    {
        name: "Foss 211",
        dorm: "Foss",
        number: "211",
        sqft: 196,
        type: "double"

    },
    {
        name: "Foss 213",
        dorm: "Foss",
        number: "213",
        sqft: 183,
        type: "double"

    },
    {
        name: "Foss 211",
        dorm: "Foss",
        number: "211",
        sqft: 196,
        type: "double"

    },
    
    {
        name: "Foss 213",
        dorm: "Foss",
        number: "213",
        sqft: 183,
        type: "double"

    },
    {
        name: "Foss 214",
        dorm: "Foss",
        number: "214",
        sqft: 191,
        type: "double"

    },
    {
        name: "Foss 215",
        dorm: "Foss",
        number: "215",
        sqft: 195,
        type: "double"

    },
    {
        name: "Foss 216",
        dorm: "Foss",
        number: "216",
        sqft: 188,
        type: "double"

    },
    {
        name: "Foss 217",
        dorm: "Foss",
        number: "217",
        sqft: 196,
        type: "double"

    },
    {
        name: "Foss 223",
        dorm: "Foss",
        number: "223",
        sqft: 591,
        type: "suite (quad)"

    },
    {
        name: "Foss 226",
        dorm: "Foss",
        number: "226",
        sqft: 101,
        type: "single"

    },
    {
        name: "Foss 228",
        dorm: "Foss",
        number: "228",
        sqft: 138,
        type: "single"

    },
    {
        name: "Foss 256",
        dorm: "Foss",
        number: "235",
        sqft: 212,
        type: "double"

    },
    {
        name: "Foss 236",
        dorm: "Foss",
        number: "236",
        sqft: 101,
        type: "single"

    },
    {
        name: "Foss 237",
        dorm: "Foss",
        number: "237",
        sqft: 127,
        type: "single"

    },
    {
        name: "Foss 238",
        dorm: "Foss",
        number: "238",
        sqft: 174,
        type: "double"

    },
    {
        name: "Foss 239",
        dorm: "Foss",
        number: "236",
        sqft: 188,
        type: "double"

    },
    {
        name: "Foss 240",
        dorm: "Foss",
        number: "240",
        sqft: 208,
        type: "double"

    },
    {
        name: "Foss 241",
        dorm: "Foss",
        number: "241",
        sqft: 552,
        type: "quad (triple)"

    },
    {
        name: "Foss 306",
        dorm: "Foss",
        number: "306",
        sqft: 520,
        type: "suite (quad)"

    },
    {
        name: "Foss 307",
        dorm: "Foss",
        number: "307",
        sqft: 563,
        type: "suite (quad)"

    },
    {
        name: "Foss 309",
        dorm: "Foss",
        number: "309",
        sqft: 107,
        type: "single"

    },
    {
        name: "Foss 310",
        dorm: "Foss",
        number: "310",
        sqft: 129,
        type: "single"

    },
    {
        name: "Foss 311",
        dorm: "Foss",
        number: "311",
        sqft: 189,
        type: "double"

    },
    {
        name: "Foss 313",
        dorm: "Foss",
        number: "313",
        sqft: 172,
        type: "double"

    },
    {
        name: "Foss 314",
        dorm: "Foss",
        number: "314",
        sqft: 197,
        type: "double"

    },
    {
        name: "Foss 315",
        dorm: "Foss",
        number: "315",
        sqft: 188,
        type: "double"

    },
    {
        name: "Foss 316",
        dorm: "Foss",
        number: "316",
        sqft: 194,
        type: "double"

    },
    {
        name: "Foss 317",
        dorm: "Foss",
        number: "317",
        sqft: 197,
        type: "double"

    },
    {
        name: "Foss 323",
        dorm: "Foss",
        number: "323",
        sqft: 578,
        type: "suite (quad)"

    },
    {
        name: "Foss 326",
        dorm: "Foss",
        number: "326",
        sqft: 101,
        type: "single"

    },
    {
        name: "Foss 328",
        dorm: "Foss",
        number: "328",
        sqft: 101,
        type: "single"

    },
    {
        name: "Foss 334",
        dorm: "Foss",
        number: "334",
        sqft: 129,
        type: "single"

    },
    {
        name: "Foss 335",
        dorm: "Foss",
        number: "335",
        sqft: 207,
        type: "double"

    },
    {
        name: "Foss 336",
        dorm: "Foss",
        number: "336",
        sqft: 131,
        type: "single"

    },
    {
        name: "Foss 337",
        dorm: "Foss",
        number: "337",
        sqft: 124,
        type: "single"

    },
    {
        name: "Foss 338",
        dorm: "Foss",
        number: "338",
        sqft: 179,
        type: "double"

    },
    {
        name: "Foss 339",
        dorm: "Foss",
        number: "339",
        sqft: 186,
        type: "double"

    },
    {
        name: "Foss 340",
        dorm: "Foss",
        number: "340",
        sqft: 214,
        type: "triple"

    },
    {
        name: "Foss 341",
        dorm: "Foss",
        number: "341",
        sqft: 602,
        type: "suite (triple)"

    },





   
]
