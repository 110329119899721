
import React from 'react';
import { Link } from 'react-router-dom';
import { AMSDormList } from '../../components/AMSDormList';
import DormRoom from '../../components/DormRoom'


function AMS() {
  return (
    <div className="dormRooms">
      <h1>Rooms</h1>
      <div className="dormList">
        {AMSDormList.map((dormRoom, index) => (
          <Link key={index} to={`/East/${dormRoom.number}`} className="button-link">
            <button className="room-button">
              <DormRoom
                name={dormRoom.name}
                dorm={dormRoom.dorm}
                number={dormRoom.number}
                sqft={dormRoom.sqft}
                type={dormRoom.type}
              />
            </button>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default AMS;
