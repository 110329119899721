import React from 'react'
import { Link } from 'react-router-dom';

function DormItem({name, image, singles, doubles, triples, url}) {
  return (
    <div className = "dormItem" style={{ backgroundImage: `url(${image})` }}>
      <h1> {name} </h1>
      <p> singles: {singles} </p>
      <p> doubles: {doubles} </p>
      <p> triples: {triples} </p>
      <Link to= {"/dorms/"+ name}>
        <button>
          View Dorm
        </button>
      </Link>

    </div>
  )
}

export default DormItem
