import React from 'react';
import '../styles/Footer.css';

function Footer() {
  return (
    <div className = "footer">
        <div className = "contactInfo"></div> 

        <p> &copy; 2024 Brunda and Jasper</p>
    </div>
  )
}

export default Footer
