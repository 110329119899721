import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { DanaDormList } from '../../components/DanaDormList';
import "../../styles/RoomDetails.css"

function DanaRoomDetails() {
  const { roomNumber } = useParams();
  const roomData = DanaDormList.find(room => room.number === roomNumber);

  const [reviews, setReviews] = useState([]);
  const [review, setReview] = useState('');
  const [overallRating, setOverallRating] = useState(1);
  const [cleanlinessRating, setCleanlinessRating] = useState(1);
  const [locationRating, setLocationRating] = useState(1);
  const [noiseRating, setNoiseRating] = useState(1);

  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await axios.get(`https://54.84.240.185:8443/reviews/${roomData.name}`);
        setReviews(response.data);
      } catch (error) {
        console.error('Error fetching reviews:', error);
      }
    };
    fetchReviews();
  }, [roomData.name]);

  const calculateAverageRating = (ratings) => {
    const sum = ratings.reduce((acc, rating) => acc + rating, 0);
    return sum / ratings.length;
  };

  const overallAverageRating = calculateAverageRating(reviews.map(review => review.overall_rating)).toFixed(1);
  const cleanlinessAverageRating = calculateAverageRating(reviews.map(review => review.cleanliness_rating)).toFixed(1);
  const locationAverageRating = calculateAverageRating(reviews.map(review => review.location_rating)).toFixed(1);
  const noiseAverageRating = calculateAverageRating(reviews.map(review => review.noise_rating)).toFixed(1);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://54.84.240.185:8443/reviews/add', {
        description: review,
        overall_rating: overallRating,
        cleanliness_rating: cleanlinessRating,
        location_rating: locationRating,
        noise_rating: noiseRating,
        room_name: roomData.name,
        dorm: roomData.dorm,
        date: new Date()
      });
      console.log(response.data);
      setReview('');
      setOverallRating(1);
      setCleanlinessRating(1);
      setLocationRating(1);
      setNoiseRating(1);
      const updatedReviews = [...reviews, response.data];
      setReviews(updatedReviews);
    } catch (error) {
      console.error('Error adding review:', error);
    }
  };

  return (
    <div className="container">
      <h1>Room Details</h1>
      <div>
        <h1>{roomData.name}</h1>
        <p>Square Feet: {roomData.sqft}</p>

        {/* Averages Section */}
        <div className="averages-section">
          <h2>Averages</h2>
          <div className="averages">
            <div>
              <p>{overallAverageRating}</p>
              <p>Overall</p>
            </div>
            <div>
              <p>{cleanlinessAverageRating}</p>
              <p>Cleanliness</p>
            </div>
            <div>
              <p>{locationAverageRating}</p>
              <p>Location</p>
            </div>
            <div>
              <p>{noiseAverageRating}</p>
              <p>Quietness</p>
            </div>
          </div>
        </div>

        {/* Reviews Section */}
        <h2>Reviews</h2>
        <div className="review-container">
          {reviews.map((review, index) => (
            <div key={index} className="review">
              <p>{review.description}</p>
              <p className="rating">Overall: {review.overall_rating}</p>
              <p className="rating">Cleanliness: {review.cleanliness_rating}</p>
              <p className="rating">Location: {review.location_rating}</p>
              <p className="rating">Quietness: {review.noise_rating}</p>
            </div>
          ))}
        </div>

        {/* Review Form */}
        <form onSubmit={handleSubmit}>
          <h2>Add Review</h2>
          <div>
            <label htmlFor="review">Review:</label>
            <textarea id="review" value={review} onChange={(e) => setReview(e.target.value)} />
          </div>
          <div>
            <label htmlFor="overallRating">Overall Rating:</label>
            <input 
              type="range" 
              id="overallRating" 
              min="0" 
              max="100" 
              step="0.1" 
              value={overallRating * 10} 
              onChange={(e) => setOverallRating(parseFloat(e.target.value) / 10)}
            />
            <output>{overallRating.toFixed(1)}</output>
          </div>
          <div>
            <label htmlFor="cleanlinessRating">Cleanliness Rating:</label>
            <input 
              type="range" 
              id="cleanlinessRating" 
              min="0" 
              max="100" 
              step="0.1" 
              value={cleanlinessRating * 10} 
              onChange={(e) => setCleanlinessRating(parseFloat(e.target.value) / 10)}
            />
            <output>{cleanlinessRating.toFixed(1)}</output>
          </div>
          <div>
            <label htmlFor="locationRating">Location Rating:</label>
            <input 
              type="range" 
              id="locationRating" 
              min="0" 
              max="100" 
              step="0.1" 
              value={locationRating * 10} 
              onChange={(e) => setLocationRating(parseFloat(e.target.value) / 10)}
            />
            <output>{locationRating.toFixed(1)}</output>
          </div>
          <div>
            <label htmlFor="noiseRating">Quietness Rating:</label>
            <input 
              type="range" 
              id="noiseRating" 
              min="0" 
              max="100" 
              step="0.1" 
              value={noiseRating * 10} 
              onChange={(e) => setNoiseRating(parseFloat(e.target.value) / 10)}
            />
            <output>{noiseRating.toFixed(1)}</output>
          </div>
          <button type="submit">Submit Review</button>
        </form>
      </div>
    </div>
  );
}

export default DanaRoomDetails;
