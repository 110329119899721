import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css"
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Dorms from "./pages/DormsHome";
import West from "./pages/Dorms/West";
import Foss from "./pages/Dorms/Foss";
import East from "./pages/Dorms/East";
import Heights from "./pages/Dorms/Heights";
import Woodman from "./pages/Dorms/Woodman";
import AMS from "./pages/Dorms/AMS";
import Coburn from "./pages/Dorms/Coburn";
import MaryLow from "./pages/Dorms/MaryLow";
import Dana from "./pages/Dorms/Dana";
import FossRoomDetails from './pages/RoomDetails/FossRoomDetails';
import WestRoomDetails from './pages/RoomDetails/WestRoomDetails';
import WoodmanRoomDetails from './pages/RoomDetails/WoodmanRoomDetails';
import EastRoomDetails from './pages/RoomDetails/EastRoomDetails';
import HeightsRoomDetails from './pages/RoomDetails/HeightsRoomDetails';
import AMSRoomDetails from './pages/RoomDetails/AMSRoomDetails';
import DanaRoomDetails from './pages/RoomDetails/DanaRoomDetails';
import MaryLowRoomDetails from './pages/RoomDetails/MaryLowRoomDetails';
import CoburnRoomDetails from './pages/RoomDetails/CoburnRoomDetails';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

function App() {
  return (<div className="App">
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element = {<Home />} />
        <Route exact path="/dorms" element = {<Dorms />} />
        <Route exact path="/dorms/West" element = {<West/>} />
        <Route exact path="/dorms/East" element = {<East/>} />
        <Route exact path="/dorms/Foss" element = {<Foss/>} />
        <Route exact path="/dorms/Woodman" element = {<Woodman/>} />
        <Route exact path="/dorms/Heights" element = {<Heights/>} />
        <Route exact path="/dorms/AMS" element = {<AMS/>} />
        <Route exact path="/dorms/Dana" element = {<Dana/>} />
        <Route exact path="/dorms/Coburn" element = {<Coburn/>} />
        <Route exact path="/dorms/MaryLow" element = {<MaryLow/>} />
        <Route exact path="/dorms/West/" element = {<West/>} />
        <Route exact path="/dorms/East/" element = {<East/>} />
        <Route exact path="/dorms/Foss/" element = {<Foss/>} />
        <Route exact path="/dorms/Woodman/" element = {<Woodman/>} />
        <Route exact path="/dorms/Heights/" element = {<Heights/>} />
        <Route exact path="/dorms/AMS/" element = {<AMS/>} />
        <Route exact path="/dorms/Dana/" element = {<Dana/>} />
        <Route exact path="/dorms/Coburn/" element = {<Coburn/>} />
        <Route exact path="/dorms/MaryLow/" element = {<MaryLow/>} />
        <Route path="/dorms/Foss/:roomNumber" element={<FossRoomDetails/>} />
        <Route path="/dorms/West/:roomNumber" element={<WestRoomDetails/>} />
        <Route path="/dorms/Woodman/:roomNumber" element={<WoodmanRoomDetails/>} />
        <Route path="/dorms/East/:roomNumber" element={<EastRoomDetails/>} />
        <Route path="/dorms/Heights/:roomNumber" element={<HeightsRoomDetails/>} />
        <Route path="/dorms/AMS/:roomNumber" element={<AMSRoomDetails/>} />
        <Route path="/dorms/Dana/:roomNumber" element={<DanaRoomDetails/>} />
        <Route path="/dorms/Coburn/:roomNumber" element={<CoburnRoomDetails/>} />
        <Route path="/dorms/MaryLow/:roomNumber" element={<MaryLowRoomDetails/>} />
      </Routes>
      <Footer />
    </Router>
  </div>
  );
  
}

export default App;



