import React from 'react';
import { Link } from 'react-router-dom';
import Colby from '../media/colby.png';
import '../styles/Home.css';

function Home() {
  return (
    <div className="home" style={{ backgroundImage: `url(${Colby})` }}> 
      <div className="headerContainer">
        
        <h1> Mule Housing</h1>
        <p> a dorm for everyone's needs</p>
        <Link to="/dorms">
          <button> Find your room </button>
        </Link>
      </div>
    </div>
  )
}

export default Home;
