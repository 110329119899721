import React from 'react'


export const EastDormList = [
   {
       name: "East 008",
       dorm: "East",
       number: "008",
       sqft: 224,
       type: "double"


   },
   {
       name: "East 011",
       dorm: "East",
       number: "011",
       sqft: 195,
       type: "double"


   },
   {
       name: "East 012",
       dorm: "East",
       number: "012",
       sqft: 97,
       type: "single"


   },
  
   {
       name: "East 014",
       dorm: "East",
       number: "014",
       sqft: 97,
       type: "single"


   },
   {
       name: "East 015",
       dorm: "East",
       number: "015",
       sqft: 195,
       type: "double"


   },
   {
       name: "East 018",
       dorm: "East",
       number: "018",
       sqft: 219,
       type: "double"


   },
   {
       name: "East 020",
       dorm: "East",
       number: "020",
       sqft: 174,
       type: "double"


   },
   {
       name: "East 021",
       dorm: "East",
       number: "021",
       sqft: 112,
       type: "single"


   },
   {
       name: "East 022",
       dorm: "East",
       number: "022",
       sqft: 233,
       type: "double"


   },
   {
       name: "East 023",
       dorm: "East",
       number: "023",
       sqft: 239,
       type: "triple"


   },
   {
       name: "East 102",
       dorm: "East",
       number: "102",
       sqft: 270,
       type: "triple"


   },
   {
       name: "East 103",
       dorm: "East",
       number: "103",
       sqft: 265,
       type: "triple"


   },
   {
       name: "East 104",
       dorm: "East",
       number: "104",
       sqft: 238,
       type: "double"


   },
   {
       name: "East 105",
       dorm: "East",
       number: "105",
       sqft: 361,
       type: "quad"


   },
   {
       name: "East 111",
       dorm: "East",
       number: "111",
       sqft: 94,
       type: "single"


   },
   {
       name: "East 115",
       dorm: "East",
       number: "111",
       sqft: 197,
       type: "double"


   },
   {
       name: "East 118",
       dorm: "East",
       number: "118",
       sqft: 218,
       type: "double"


   },
   {
       name: "East 120",
       dorm: "East",
       number: "120",
       sqft: 184,
       type: "double"


   },
   {
       name: "East 122",
       dorm: "East",
       number: "122",
       sqft: 146,
       type: "double"


   },
   {
       name: "East 123",
       dorm: "East",
       number: "123",
       sqft: 148,
       type: "triple"


   },
   {
       name: "East 124",
       dorm: "East",
       number: "124",
       sqft: 172,
       type: "triple"


   },
   {
       name: "East 130",
       dorm: "East",
       number: "130",
       sqft: 94,
       type: "single"


   },
   {
       name: "East 131",
       dorm: "East",
       number: "131",
       sqft: 113,
       type: "single"


   },
   {
       name: "East 135",
       dorm: "East",
       number: "135",
       sqft: 113,
       type: "single"


   },
   {
       name: "East 136",
       dorm: "East",
       number: "136",
       sqft: 93,
       type: "single"


   },
   {
       name: "East 202",
       dorm: "East",
       number: "202",
       sqft: 260,
       type: "triple"


   },
   {
       name: "East 203",
       dorm: "East",
       number: "203",
       sqft: 282,
       type: "triple"


   },
   {
       name: "East 204",
       dorm: "East",
       number: "204",
       sqft: 136,
       type: "double"


   },
   {
       name: "East 205",
       dorm: "East",
       number: "205",
       sqft: 185,
       type: "double"


   },
   {
       name: "East 208",
       dorm: "East",
       number: "208",
       sqft: 223,
       type: "double"


   },
   {
       name: "East 211",
       dorm: "East",
       number: "211",
       sqft: 195,
       type: "double"


   },
   {
       name: "East 212",
       dorm: "East",
       number: "212",
       sqft: 100,
       type: "single"


   },
   {
       name: "East 213",
       dorm: "East",
       number: "213",
       sqft: 98,
       type: "single"


   },
   {
       name: "East 214",
       dorm: "East",
       number: "214",
       sqft: 98,
       type: "single"


   },
   {
       name: "East 215",
       dorm: "East",
       number: "214",
       sqft: 197,
       type: "double"


   },
   {
       name: "East 217",
       dorm: "East",
       number: "217",
       sqft: 97,
       type: "single"


   },
   {
       name: "East 218",
       dorm: "East",
       number: "218",
       sqft: 121,
       type: "single"


   },
   {
       name: "East 220",
       dorm: "East",
       number: "220",
       sqft: 177,
       type: "double"


   },
   {
       name: "East 222",
       dorm: "East",
       number: "222",
       sqft: 249,
       type: "triple"


   },
   {
       name: "East 223",
       dorm: "East",
       number: "223",
       sqft: 277,
       type: "triple"


   },
   {
       name: "East 224",
       dorm: "East",
       number: "224",
       sqft: 272,
       type: "triple"


   },
   {
       name: "East 230",
       dorm: "East",
       number: "230",
       sqft: 94,
       type: "single"


   },
   {
       name: "East 231",
       dorm: "East",
       number: "231",
       sqft: 114,
       type: "single"


   },
   {
       name: "East 232",
       dorm: "East",
       number: "232",
       sqft: 97,
       type: "single"


   },
   {
       name: "East 233",
       dorm: "East",
       number: "233",
       sqft: 104,
       type: "single"


   },
   {
       name: "East 234",
       dorm: "East",
       number: "234",
       sqft: 99,
       type: "single"


   },
   {
       name: "East 235",
       dorm: "East",
       number: "235",
       sqft: 113,
       type: "single"


   },
   {
       name: "East 236",
       dorm: "East",
       number: "236",
       sqft: 93,
       type: "single"


   },
   {
       name: "East 302",
       dorm: "East",
       number: "302",
       sqft: 278,
       type: "triple"


   },
   {
       name: "East 303",
       dorm: "East",
       number: "303",
       sqft: 264,
       type: "triple"


   },
   {
       name: "East 304",
       dorm: "East",
       number: "304",
       sqft: 134,
       type: "double"


   },
   {
       name: "East 305",
       dorm: "East",
       number: "305",
       sqft: 209,
       type: "double"


   },
   {
       name: "East 308",
       dorm: "East",
       number: "308",
       sqft: 233,
       type: "double"


   },
   {
       name: "East 311",
       dorm: "East",
       number: "311",
       sqft: 195,
       type: "double"


   },
   {
       name: "East 312",
       dorm: "East",
       number: "312",
       sqft: 100,
       type: "single"


   },
   {
       name: "East 313",
       dorm: "East",
       number: "313",
       sqft: 98,
       type: "single"


   },
   {
       name: "East 314",
       dorm: "East",
       number: "314",
       sqft: 98,
       type: "single"


   },
   {
       name: "East 315",
       dorm: "East",
       number: "315",
       sqft: 197,
       type: "double"


   },
   {
       name: "East 317",
       dorm: "East",
       number: "317",
       sqft: 97,
       type: "single"


   },
   {
       name: "East 318",
       dorm: "East",
       number: "318",
       sqft: 121,
       type: "single"


   },
   {
       name: "East 320",
       dorm: "East",
       number: "320",
       sqft: 206,
       type: "double"


   },
   {
       name: "East 322",
       dorm: "East",
       number: "322",
       sqft: 246,
       type: "double"


   },
   {
       name: "East 323",
       dorm: "East",
       number: "323",
       sqft: 252,
       type: "triple"


   },
   {
       name: "East 324",
       dorm: "East",
       number: "324",
       sqft: 259,
       type: "triple"


   },
   {
       name: "East 330",
       dorm: "East",
       number: "330",
       sqft: 95,
       type: "single"


   },
   {
       name: "East 331",
       dorm: "East",
       number: "331",
       sqft: 114,
       type: "single"


   },
   {
       name: "East 332",
       dorm: "East",
       number: "332",
       sqft: 97,
       type: "single"


   },
   {
       name: "East 333",
       dorm: "East",
       number: "333",
       sqft: 104,
       type: "single"


   },
   {
       name: "East 334",
       dorm: "East",
       number: "334",
       sqft: 100,
       type: "single"


   },
   {
       name: "East 335",
       dorm: "East",
       number: "335",
       sqft: 111,
       type: "single"


   },
   {
       name: "East 336",
       dorm: "East",
       number: "336",
       sqft: 93,
       type: "single"


   },
  
]

