import React from 'react'

export const WestDormList = [
    {
        name: "West 011",
        dorm: "West",
        number: "011",
        sqft: 195,
        type: "double"

    },
    {
        name: "West 012",
        dorm: "West",
        number: "012",
        sqft: 97,
        type: "single"

    },
    
    {
        name: "West 014",
        dorm: "West",
        number: "014",
        sqft: 97,
        type: "single"

    },
    {
        name: "West 015",
        dorm: "West",
        number: "015",
        sqft: 195,
        type: "double"

    },
    {
        name: "West 018",
        dorm: "West",
        number: "018",
        sqft: 219,
        type: "double"

    },
    {
        name: "West 020",
        dorm: "West",
        number: "020",
        sqft: 174,
        type: "double"

    },
    {
        name: "West 021",
        dorm: "West",
        number: "021",
        sqft: 112,
        type: "single"

    },
    {
        name: "West 022",
        dorm: "West",
        number: "022",
        sqft: 233,
        type: "double"

    },
    {
        name: "West 023",
        dorm: "West",
        number: "023",
        sqft: 239,
        type: "triple"

    },
    {
        name: "West 102",
        dorm: "West",
        number: "102",
        sqft: 270,
        type: "triple"

    },
    {
        name: "West 103",
        dorm: "West",
        number: "103",
        sqft: 265,
        type: "triple"

    },
    {
        name: "West 104",
        dorm: "West",
        number: "104",
        sqft: 238,
        type: "double"

    },
    {
        name: "West 105",
        dorm: "West",
        number: "105",
        sqft: 361,
        type: "quad"

    },
    {
        name: "West 111",
        dorm: "West",
        number: "111",
        sqft: 94,
        type: "single"

    },
    {
        name: "West 115",
        dorm: "West",
        number: "111",
        sqft: 197,
        type: "double"

    },
    {
        name: "West 118",
        dorm: "West",
        number: "118",
        sqft: 218,
        type: "double"

    },
    {
        name: "West 120",
        dorm: "West",
        number: "120",
        sqft: 184,
        type: "double"

    },
    {
        name: "West 122",
        dorm: "West",
        number: "122",
        sqft: 146,
        type: "double"

    },
    {
        name: "West 123",
        dorm: "West",
        number: "123",
        sqft: 148,
        type: "triple"

    },
    {
        name: "West 124",
        dorm: "West",
        number: "124",
        sqft: 172,
        type: "triple"

    },
    {
        name: "West 130",
        dorm: "West",
        number: "130",
        sqft: 94,
        type: "single"

    },
    {
        name: "West 131",
        dorm: "West",
        number: "131",
        sqft: 113,
        type: "single"

    },
    {
        name: "West 135",
        dorm: "West",
        number: "135",
        sqft: 113,
        type: "single"

    },
    {
        name: "West 136",
        dorm: "West",
        number: "136",
        sqft: 93,
        type: "single"

    },
    {
        name: "West 202",
        dorm: "West",
        number: "202",
        sqft: 260,
        type: "triple"

    },
    {
        name: "West 203",
        dorm: "West",
        number: "203",
        sqft: 282,
        type: "triple"

    },
    {
        name: "West 204",
        dorm: "West",
        number: "204",
        sqft: 136,
        type: "double"

    },
    {
        name: "West 205",
        dorm: "West",
        number: "205",
        sqft: 185,
        type: "double"

    },
    {
        name: "West 208",
        dorm: "West",
        number: "208",
        sqft: 223,
        type: "double"

    },
    {
        name: "West 211",
        dorm: "West",
        number: "211",
        sqft: 195,
        type: "double"

    },
    {
        name: "West 212",
        dorm: "West",
        number: "212",
        sqft: 100,
        type: "single"

    },
    {
        name: "West 213",
        dorm: "West",
        number: "213",
        sqft: 98,
        type: "single"

    },
    {
        name: "West 214",
        dorm: "West",
        number: "214",
        sqft: 98,
        type: "single"

    },
    {
        name: "West 215",
        dorm: "West",
        number: "214",
        sqft: 197,
        type: "double"

    },
    {
        name: "West 217",
        dorm: "West",
        number: "217",
        sqft: 97,
        type: "single"

    },
    {
        name: "West 218",
        dorm: "West",
        number: "218",
        sqft: 121,
        type: "single"

    },
    {
        name: "West 220",
        dorm: "West",
        number: "220",
        sqft: 177,
        type: "double"

    },
    {
        name: "West 222",
        dorm: "West",
        number: "222",
        sqft: 249,
        type: "triple"

    },
    {
        name: "West 223",
        dorm: "West",
        number: "223",
        sqft: 277,
        type: "triple"

    },
    {
        name: "West 224",
        dorm: "West",
        number: "224",
        sqft: 272,
        type: "triple"

    },
    {
        name: "West 230",
        dorm: "West",
        number: "230",
        sqft: 94,
        type: "single"

    },
    {
        name: "West 231",
        dorm: "West",
        number: "231",
        sqft: 114,
        type: "single"

    },
    {
        name: "West 232",
        dorm: "West",
        number: "232",
        sqft: 97,
        type: "single"

    },
    {
        name: "West 233",
        dorm: "West",
        number: "233",
        sqft: 104,
        type: "single"

    },
    {
        name: "West 234",
        dorm: "West",
        number: "234",
        sqft: 99,
        type: "single"

    },
    {
        name: "West 235",
        dorm: "West",
        number: "235",
        sqft: 113,
        type: "single"

    },
    {
        name: "West 236",
        dorm: "West",
        number: "236",
        sqft: 93,
        type: "single"

    },
    {
        name: "West 302",
        dorm: "West",
        number: "302",
        sqft: 278,
        type: "triple"

    },
    {
        name: "West 303",
        dorm: "West",
        number: "303",
        sqft: 264,
        type: "triple"

    },
    {
        name: "West 304",
        dorm: "West",
        number: "304",
        sqft: 134,
        type: "double"

    },
    {
        name: "West 305",
        dorm: "West",
        number: "305",
        sqft: 209,
        type: "double"

    },
    {
        name: "West 308",
        dorm: "West",
        number: "308",
        sqft: 233,
        type: "double"

    },
    {
        name: "West 311",
        dorm: "West",
        number: "311",
        sqft: 195,
        type: "double"

    },
    {
        name: "West 312",
        dorm: "West",
        number: "312",
        sqft: 100,
        type: "single"

    },
    {
        name: "West 313",
        dorm: "West",
        number: "313",
        sqft: 98,
        type: "single"

    },
    {
        name: "West 314",
        dorm: "West",
        number: "314",
        sqft: 98,
        type: "single"

    },
    {
        name: "West 315",
        dorm: "West",
        number: "315",
        sqft: 197,
        type: "double"

    },
    {
        name: "West 317",
        dorm: "West",
        number: "317",
        sqft: 97,
        type: "single"

    },
    {
        name: "West 318",
        dorm: "West",
        number: "318",
        sqft: 121,
        type: "single"

    },
    {
        name: "West 320",
        dorm: "West",
        number: "320",
        sqft: 206,
        type: "double"

    },
    {
        name: "West 322",
        dorm: "West",
        number: "322",
        sqft: 246,
        type: "double"

    },
    {
        name: "West 323",
        dorm: "West",
        number: "323",
        sqft: 252,
        type: "triple"

    },
    {
        name: "West 324",
        dorm: "West",
        number: "324",
        sqft: 259,
        type: "triple"

    },
    {
        name: "West 330",
        dorm: "West",
        number: "330",
        sqft: 95,
        type: "single"

    },
    {
        name: "West 331",
        dorm: "West",
        number: "331",
        sqft: 114,
        type: "single"

    },
    {
        name: "West 332",
        dorm: "West",
        number: "332",
        sqft: 97,
        type: "single"

    },
    {
        name: "West 333",
        dorm: "West",
        number: "333",
        sqft: 104,
        type: "single"

    },
    {
        name: "West 334",
        dorm: "West",
        number: "334",
        sqft: 100,
        type: "single"

    },
    {
        name: "West 335",
        dorm: "West",
        number: "335",
        sqft: 111,
        type: "single"

    },
    {
        name: "West 336",
        dorm: "West",
        number: "336",
        sqft: 93,
        type: "single"

    },
    
]