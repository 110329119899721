export const WoodmanDormList = [
    {
        name: "Woodman 064",
        dorm: "Woodman",
        number: "064",
        sqft: 192,
        type: "double"

    },
    {
        name: "Woodman 066",
        dorm: "Woodman",
        number: "066",
        sqft: 203,
        type: "double"

    },
    {
        name: "Woodman 058",
        dorm: "Woodman",
        number: "058",
        sqft: 145,
        type: "double"

    },
    
    {
        name: "Woodman 070",
        dorm: "Woodman",
        number: "070",
        sqft: 208,
        type: "triple"

    },
    {
        name: "Woodman 072",
        dorm: "Woodman",
        number: "072",
        sqft: 114,
        type: "single"

    },
    {
        name: "Woodman 074",
        dorm: "Woodman",
        number: "074",
        sqft: 563,
        type: "suite (triple) "

    },
    {
        name: "Woodman 076",
        dorm: "Woodman",
        number: "076",
        sqft: 189,
        type: "double"

    },
    {
        name: "Woodman 151",
        dorm: "Woodman",
        number: "151",
        sqft: 112,
        type: "single"

    },
    {
        name: "Woodman 152",
        dorm: "Woodman",
        number: "152",
        sqft: 191,
        type: "double"

    },
    {
        name: "Woodman 153",
        dorm: "Woodman",
        number: "153",
        sqft: 113,
        type: "single"

    },
    {
        name: "Woodman 154",
        dorm: "Woodman",
        number: "154",
        sqft: 142,
        type: "double"

    },
    {
        name: "Woodman 160",
        dorm: "Woodman",
        number: "160",
        sqft: 146,
        type: "double"

    },
    {
        name: "Woodman 162",
        dorm: "Woodman",
        number: "162",
        sqft: 205,
        type: "double"

    },
    {
        name: "Woodman 164",
        dorm: "Woodman",
        number: "164",
        sqft: 195,
        type: "double"

    },
    {
        name: "Woodman 165",
        dorm: "Woodman",
        number: "165",
        sqft: 181,
        type: "double"

    },
    {
        name: "Woodman 166",
        dorm: "Woodman",
        number: "166",
        sqft: 207,
        type: "double"

    },
    {
        name: "Woodman 167",
        dorm: "Woodman",
        number: "167",
        sqft: 117,
        type: "single"

    },
    {
        name: "Woodman 168",
        dorm: "Woodman",
        number: "168",
        sqft: 146,
        type: "single"

    },
    {
        name: "Woodman 170",
        dorm: "Woodman",
        number: "170",
        sqft: 211,
        type: "double"

    },
    {
        name: "Woodman 172",
        dorm: "Woodman",
        number: "172",
        sqft: 114,
        type: "single"

    },
    {
        name: "Woodman 174",
        dorm: "Woodman",
        number: "170",
        sqft: 571,
        type: "suite (triple)"

    },
    {
        name: "Woodman 176",
        dorm: "Woodman",
        number: "176",
        sqft: 197,
        type: "double"

    },
    {
        name: "Woodman 251",
        dorm: "Woodman",
        number: "251",
        sqft: 109,
        type: "single"

    },
    {
        name: "Woodman 252",
        dorm: "Woodman",
        number: "252",
        sqft: 195,
        type: "double"

    },
    {
        name: "Woodman 253",
        dorm: "Woodman",
        number: "253",
        sqft: 117,
        type: "single"

    },
    {
        name: "Woodman 254",
        dorm: "Woodman",
        number: "254",
        sqft: 120,
        type: "single"

    },
    {
        name: "Woodman 258",
        dorm: "Woodman",
        number: "258",
        sqft: 119,
        type: "single"

    },
    {
        name: "Woodman 260",
        dorm: "Woodman",
        number: "260",
        sqft: 120,
        type: "single"

    },
    {
        name: "Woodman 262",
        dorm: "Woodman",
        number: "262",
        sqft: 254,
        type: "triple"

    },
    {
        name: "Woodman 264",
        dorm: "Woodman",
        number: "264",
        sqft: 195,
        type: "double"

    },
    {
        name: "Woodman 265",
        dorm: "Woodman",
        number: "265",
        sqft: 180,
        type: "double"

    },
    {
        name: "Woodman 266",
        dorm: "Woodman",
        number: "266",
        sqft: 207,
        type: "double"

    },
    {
        name: "Woodman 267",
        dorm: "Woodman",
        number: "267",
        sqft: 115,
        type: "single"

    },
    {
        name: "Woodman 268",
        dorm: "Woodman",
        number: "268",
        sqft: 146,
        type: "single"

    },
    {
        name: "Woodman 270",
        dorm: "Woodman",
        number: "270",
        sqft: 211,
        type: "double"

    },
    {
        name: "Woodman 272",
        dorm: "Woodman",
        number: "272",
        sqft: 115,
        type: "single"

    },
    {
        name: "Woodman 274",
        dorm: "Woodman",
        number: "274",
        sqft: 570,
        type: "suite (triple)"

    },
    {
        name: "Woodman 276",
        dorm: "Woodman",
        number: "272",
        sqft: 197,
        type: "double"

    },
    {
        name: "Woodman 351",
        dorm: "Woodman",
        number: "351",
        sqft: 109,
        type: "single"

    },
    {
        name: "Woodman 352",
        dorm: "Woodman",
        number: "352",
        sqft: 195,
        type: "double"

    },
    {
        name: "Woodman 353",
        dorm: "Woodman",
        number: "353",
        sqft: 117,
        type: "single"

    },
    {
        name: "Woodman 354",
        dorm: "Woodman",
        number: "354",
        sqft: 120,
        type: "single"

    },
    {
        name: "Woodman 358",
        dorm: "Woodman",
        number: "358",
        sqft: 119,
        type: "single"

    },
    {
        name: "Woodman 360",
        dorm: "Woodman",
        number: "360",
        sqft: 120,
        type: "single"

    },
    {
        name: "Woodman 362",
        dorm: "Woodman",
        number: "362",
        sqft: 253,
        type: "triple"

    },
    {
        name: "Woodman 364",
        dorm: "Woodman",
        number: "364",
        sqft: 195,
        type: "double"

    },
    {
        name: "Woodman 365",
        dorm: "Woodman",
        number: "365",
        sqft: 180,
        type: "double"

    },
    {
        name: "Woodman 366",
        dorm: "Woodman",
        number: "366",
        sqft: 207,
        type: "double"

    },
    {
        name: "Woodman 367",
        dorm: "Woodman",
        number: "367",
        sqft: 115,
        type: "single"

    },
    {
        name: "Woodman 368",
        dorm: "Woodman",
        number: "368",
        sqft: 148,
        type: "single"

    },
    {
        name: "Woodman 370",
        dorm: "Woodman",
        number: "370",
        sqft: 207,
        type: "double"

    },
    {
        name: "Woodman 372",
        dorm: "Woodman",
        number: "372",
        sqft: 119,
        type: "single"

    },
    {
        name: "Woodman 374",
        dorm: "Woodman",
        number: "374",
        sqft: 571,
        type: "suite (triple) "

    },
    {
        name: "Woodman 376",
        dorm: "Woodman",
        number: "376",
        sqft: 197,
        type: "double"

    },
]