export const AMSDormList = [
    {
        name: "Anthony 173",
        dorm: "AMS",
        number: "173",
        sqft: 112,
        type: "single"
 
 
    },
    {
        name: "Anthony 174",
        dorm: "AMS",
        number: "174",
        sqft: 112,
        type: "single"
 
 
    },
    {
        name: "Anthony 175",
        dorm: "AMS",
        number: "175",
        sqft: 112,
        type: "single"
 
 
    },
    {
        name: "Anthony 176",
        dorm: "AMS",
        number: "176",
        sqft: 205,
        type: "double"
 
 
    },
    {
        name: "Anthony 177",
        dorm: "AMS",
        number: "177",
        sqft: 215,
        type: "triple"
 
 
    },
    {
        name: "Anthony 178",
        dorm: "AMS",
        number: "178",
        sqft: 209,
        type: "triple"
 
 
    },
    {
        name: "Anthony 179",
        dorm: "AMS",
        number: "179",
        sqft: 207,
        type: "double"
 
 
    },
    {
        name: "Anthony 180",
        dorm: "AMS",
        number: "180",
        sqft: 109,
        type: "single"
 
 
    },
    {
        name: "Anthony 181",
        dorm: "AMS",
        number: "181",
        sqft: 109,
        type: "single"
 
 
    },
    {
        name: "Anthony 182",
        dorm: "AMS",
        number: "182",
        sqft: 109,
        type: "single"
 
 
    },
    {
        name: "Anthony 192",
        dorm: "AMS",
        number: "192",
        sqft: 191,
        type: "double"
 
 
    },
    {
        name: "Anthony 193",
        dorm: "AMS",
        number: "193",
        sqft: 98,
        type: "single"
 
 
    },
    {
        name: "Anthony 194",
        dorm: "AMS",
        number: "194",
        sqft: 302,
        type: "triple"
 
 
    },
    {
        name: "Anthony 195",
        dorm: "AMS",
        number: "195",
        sqft: 141,
        type: "single"
 
 
    },
    {
        "name": "Mitchell 116",
        "dorm": "AMS",
        "number": "116",
        "sqft": 205,
        "type": "double"
    },
    {
        "name": "Mitchell 117",
        "dorm": "AMS",
        "number": "117",
        "sqft": 209,
        "type": "triple"
    },
    {
        "name": "Mitchell 118",
        "dorm": "AMS",
        "number": "118",
        "sqft": 213,
        "type": "triple"
    },
    {
        "name": "Mitchell 119",
        "dorm": "AMS",
        "number": "119",
        "sqft": 206,
        "type": "double"
    },
    {
        "name": "Mitchell 132",
        "dorm": "AMS",
        "number": "132",
        "sqft": 204,
        "type": "double"
    },
    {
        "name": "Mitchell 134",
        "dorm": "AMS",
        "number": "134",
        "sqft": 300,
        "type": "triple"
    },
    {
        "name": "Mitchell 135",
        "dorm": "AMS",
        "number": "135",
        "sqft": 139,
        "type": "single"
    },
    {
        "name": "Schupf 155",
        "dorm": "AMS",
        "number": "155",
        "sqft": 964,
        "type": "suite (six person)"
    },
    {
        "name": "Schupf 163",
        "dorm": "AMS",
        "number": "163",
        "sqft": 131,
        "type": "single"
    },
    {
        "name": "Anthony 273",
        "dorm": "AMS",
        "number": "273",
        "sqft": 103,
        "type": "single"
    },
    {
        "name": "Anthony 274",
        "dorm": "AMS",
        "number": "274",
        "sqft": 108,
        "type": "single"
    },
    {
        "name": "Anthony 275",
        "dorm": "AMS",
        "number": "275",
        "sqft": 107,
        "type": "single"
    },
    {
        "name": "Anthony 276",
        "dorm": "AMS",
        "number": "276",
        "sqft": 208,
        "type": "double"
    },
    {
        "name": "Anthony 277",
        "dorm": "AMS",
        "number": "277",
        "sqft": 210,
        "type": "double"
    },
    {
        "name": "Anthony 278",
        "dorm": "AMS",
        "number": "278",
        "sqft": 213,
        "type": "triple"
    },
    {
        "name": "Anthony 279",
        "dorm": "AMS",
        "number": "279",
        "sqft": 217,
        "type": "triple"
    },
    {
        "name": "Anthony 280",
        "dorm": "AMS",
        "number": "280",
        "sqft": 114,
        "type": "single"
    },
    {
        "name": "Anthony 281",
        "dorm": "AMS",
        "number": "281",
        "sqft": 115,
        "type": "single"
    },
    {
        "name": "Anthony 282",
        "dorm": "AMS",
        "number": "282",
        "sqft": 113,
        "type": "single"
    },
    {
        "name": "Anthony 294",
        "dorm": "AMS",
        "number": "294",
        "sqft": 680,
        "type": "suite (4 person)"
    },
    {
        "name": "Mitchell 213",
        "dorm": "AMS",
        "number": "213",
        "sqft": 112,
        "type": "single"
    },
    {
        "name": "Mitchell 214",
        "dorm": "AMS",
        "number": "214",
        "sqft": 113,
        "type": "single"
    },
    {
        "name": "Mitchell 216",
        "dorm": "AMS",
        "number": "216",
        "sqft": 214,
        "type": "double"
    },
    {
        "name": "Mitchell 217",
        "dorm": "AMS",
        "number": "217",
        "sqft": 212,
        "type": "double"
    },
    {
        "name": "Mitchell 218",
        "dorm": "AMS",
        "number": "218",
        "sqft": 210,
        "type": "double"
    },
    {
        "name": "Mitchell 217",
        "dorm": "AMS",
        "number": "217",
        "sqft": 212,
        "type": "double"
    },
    {
        "name": "Mitchell 218",
        "dorm": "AMS",
        "number": "218",
        "sqft": 210,
        "type": "double"
    },
    {
        "name": "Mitchell 219",
        "dorm": "AMS",
        "number": "219",
        "sqft": 204,
        "type": "double"
    },
    {
        "name": "Mitchell 220",
        "dorm": "AMS",
        "number": "220",
        "sqft": 107,
        "type": "single"
    },
    {
        "name": "Mitchell 221",
        "dorm": "AMS",
        "number": "221",
        "sqft": 106,
        "type": "single"
    },
    {
        "name": "Mitchell 222",
        "dorm": "AMS",
        "number": "222",
        "sqft": 106,
        "type": "single"
    },
    {
        "name": "Mitchell 234",
        "dorm": "AMS",
        "number": "234",
        "sqft": 650,
        "type": "suite (4-person)"
    },
    {
        "name": "Schupf 252",
        "dorm": "AMS",
        "number": "252",
        "sqft": 115,
        "type": "single"
    },
    {
        "name": "Schupf 251",
        "dorm": "AMS",
        "number": "251",
        "sqft": 113,
        "type": "single"
    },
    {
        "name": "Schupf 250",
        "dorm": "AMS",
        "number": "250",
        "sqft": 113,
        "type": "single"
    },
    {
        "name": "Schupf 250",
        "dorm": "AMS",
        "number": "250",
        "sqft": 113,
        "type": "single"
    },
    {
        "name": "Schupf 249",
        "dorm": "AMS",
        "number": "249",
        "sqft": 208,
        "type": "double"
    },
    {
        "name": "Schupf 248",
        "dorm": "AMS",
        "number": "248",
        "sqft": 209,
        "type": "double"
    },
    {
        "name": "Schupf 247",
        "dorm": "AMS",
        "number": "247",
        "sqft": 207,
        "type": "double"
    },
    {
        "name": "Schupf 246",
        "dorm": "AMS",
        "number": "246",
        "sqft": 201,
        "type": "single"
    },
    {
        "name": "Schupf 245",
        "dorm": "AMS",
        "number": "245",
        "sqft": 108,
        "type": "single"
    },
    {
        "name": "Schupf 244",
        "dorm": "AMS",
        "number": "244",
        "sqft": 107,
        "type": "single"
    },
    {
        "name": "Schupf 243",
        "dorm": "AMS",
        "number": "243",
        "sqft": 108,
        "type": "single"
    },
    {
        "name": "Schupf 261",
        "dorm": "AMS",
        "number": "261",
        "sqft": 735,
        "type": "suite (4-person)"
    },
    {
        "name": "Anthony 373",
        "dorm": "AMS",
        "number": "373",
        "sqft": 107,
        "type": "single"
    },
    {
        "name": "Anthony 374",
        "dorm": "AMS",
        "number": "374",
        "sqft": 109,
        "type": "single"
    },
    {
        "name": "Anthony 375",
        "dorm": "AMS",
        "number": "375",
        "sqft": 109,
        "type": "single"
    },
    {
        "name": "Anthony 376",
        "dorm": "AMS",
        "number": "376",
        "sqft": 207,
        "type": "double"
    },
    {
        "name": "Anthony 377",
        "dorm": "AMS",
        "number": "377",
        "sqft": 209,
        "type": "double"
    },
    {
        "name": "Anthony 378",
        "dorm": "AMS",
        "number": "378",
        "sqft": 210,
        "type": "double"
    },
    {
        "name": "Anthony 379",
        "dorm": "AMS",
        "number": "379",
        "sqft": 212,
        "type": "double"
    },
    {
        "name": "Anthony 380",
        "dorm": "AMS",
        "number": "380",
        "sqft": 112,
        "type": "single"
    },
    {
        "name": "Anthony 381",
        "dorm": "AMS",
        "number": "381",
        "sqft": 113,
        "type": "single"
    },
    {
        "name": "Anthony 382",
        "dorm": "AMS",
        "number": "382",
        "sqft": 112,
        "type": "single"
    },
    {
        "name": "Anthony 394",
        "dorm": "AMS",
        "number": "394",
        "sqft": 676,
        "type": "single"
    },
    {
        "name": "Mitchell 313",
        "dorm": "AMS",
        "number": "313",
        "sqft": 108,
        "type": "single"
    },
    {
        "name": "Mitchell 314",
        "dorm": "AMS",
        "number": "314",
        "sqft": 110,
        "type": "single"
    },
    {
        "name": "Mitchell 315",
        "dorm": "AMS",
        "number": "315",
        "sqft": 110,
        "type": "single"
    },
    {
        "name": "Mitchell 316",
        "dorm": "AMS",
        "number": "316",
        "sqft": 210,
        "type": "single"
    },
    {
        "name": "Mitchell 317",
        "dorm": "AMS",
        "number": "317",
        "sqft": 212,
        "type": "triple"
    },
    {
        "name": "Mitchell 318",
        "dorm": "AMS",
        "number": "318",
        "sqft": 211,
        "type": "triple"
    },
    {
        "name": "Mitchell 319",
        "dorm": "AMS",
        "number": "319",
        "sqft": 209,
        "type": "double"
    },
    {
        "name": "Mitchell 320",
        "dorm": "AMS",
        "number": "320",
        "sqft": 111,
        "type": "single"
    },
    {
        "name": "Mitchell 321",
        "dorm": "AMS",
        "number": "321",
        "sqft": 110,
        "type": "single"
    },
    {
        "name": "Mitchell 322",
        "dorm": "AMS",
        "number": "322",
        "sqft": 109,
        "type": "single"
    },
    {
        "name": "Mitchell 334",
        "dorm": "AMS",
        "number": "322",
        "sqft": 664,
        "type": "suite (4-person)"
    },
    {
        "name": "Schupf 352",
        "dorm": "AMS",
        "number": "352",
        "sqft": 108,
        "type": "single"
    },
    {
        "name": "Schupf 351",
        "dorm": "AMS",
        "number": "351",
        "sqft": 111,
        "type": "single"
    },
    {
        "name": "Schupf 350",
        "dorm": "AMS",
        "number": "350",
        "sqft": 110,
        "type": "single"
    },
    {
        "name": "Schupf 349",
        "dorm": "AMS",
        "number": "349",
        "sqft": 209,
        "type": "double"
    },
    {
        "name": "Schupf 348",
        "dorm": "AMS",
        "number": "348",
        "sqft": 206,
        "type": "double"
    },
    {
        "name": "Schupf 347",
        "dorm": "AMS",
        "number": "349",
        "sqft": 211,
        "type": "triple"
    },
    {
        "name": "Schupf 346",
        "dorm": "AMS",
        "number": "346",
        "sqft": 206,
        "type": "double"
    },
    {
        "name": "Schupf 345",
        "dorm": "AMS",
        "number": "345",
        "sqft": 108,
        "type": "double"
    },
    {
        "name": "Schupf 344",
        "dorm": "AMS",
        "number": "344",
        "sqft": 109,
        "type": "double"
    },
    {
        "name": "Schupf 343",
        "dorm": "AMS",
        "number": "343",
        "sqft": 109,
        "type": "double"
    },
    {
        "name": "Schupf 361",
        "dorm": "AMS",
        "number": "361",
        "sqft": 751,
        "type": "suite (four person)"
    },





]