import West from '../media/dorms/West.png'
import Foss from '../media/dorms/Foss.png'
import Coburn from '../media/dorms/Coburn.png'
import AMS from '../media/dorms/AMS.png'
import Apts from '../media/dorms/Apts.png'
import Averill from '../media/dorms/Averill.png'
import Carol from '../media/dorms/Carol.png'
import Dana from '../media/dorms/Dana.png'
import Drummond from '../media/dorms/Drummond.png'
import East from '../media/dorms/East.png'
import GoHo from '../media/dorms/GoHo.png'
import Heights from '../media/dorms/Heights.png'
import Jane from '../media/dorms/Jane.png'
import Johnson from '../media/dorms/Johnson.png'
import Leonard from '../media/dorms/Leonard.png'
import Marriner from '../media/dorms/Marriner.png'
import MaryLow from '../media/dorms/Marylow.png'
import Jacqueline from '../media/dorms/Nunez.png'
import Paula from '../media/dorms/Paula.png'
import Pierce from '../media/dorms/Pierce.png'
import Piper from '../media/dorms/Piper.png'
import PiWi from '../media/dorms/PiWi.png'
import Roberts from '../media/dorms/Roberts.png'
import Taylor from '../media/dorms/Taylor.png'
import Treworgy from '../media/dorms/Treworgy.png'
import Sturtevant from '../media/dorms/Sturtevant.png'
import Williams from '../media/dorms/Williams.png'
import Woodman from '../media/dorms/Woodman.png'

export const DormList = [
    {
        name: "West",
        image: West,
        singles: 32,
        doubles: 24,
        triples: 14,

    },
    {
        name: "East",
        image: East,
        singles: 32,
        doubles: 24,
        triples: 14,

    },

    {
        name: "Foss",
        image: Foss,
        singles: 19,
        doubles: 30,
        triples: 4,

    },
    {
        name: "Coburn",
        image: Coburn,
        singles: 46,
        doubles: 16,
        triples: 4,

    },
    {
        name: "MaryLow",
        image: MaryLow,
        singles: 46,
        doubles: 16,
        triples: 4,

    },
    {
        name: "Woodman",
        image: Woodman,
        singles: 22,
        doubles: 25,
        triples: 7,

    },

    {
        name: "Heights",
        image: Heights,
        singles: 41,
        doubles: 7,
        triples: 0,

    },
    {
        name: "AMS",
        image: AMS,
        singles: 41,
        doubles: 7,
        triples: 0,

    },
    {
        name: "Dana",
        image: Dana,
        singles: 24,
        doubles: 38,
        triples: 5,

    },
    {
        name: "Taylor",
        image: Taylor,
        singles: 24,
        doubles: 38,
        triples: 5,

    },
    {
        name: "Leonard",
        image: Leonard,
        singles: 24,
        doubles: 38,
        triples: 5,

    },
    {
        name: "Marriner",
        image: Marriner,
        singles: 24,
        doubles: 38,
        triples: 5,

    },
    {
        name: "Williams",
        image: Williams,
        singles: 24,
        doubles: 38,
        triples: 5,

    },
    {
        name: "Averill",
        image: Averill,
        singles: 24,
        doubles: 38,
        triples: 5,

    },
    {
        name: "Johnson",
        image: Johnson,
        singles: 24,
        doubles: 38,
        triples: 5,

    },
    {
        name: "Piper",
        image: Piper,
        singles: 24,
        doubles: 38,
        triples: 5,

    },
    {
        name: "Drummond",
        image: Drummond,
        singles: 24,
        doubles: 38,
        triples: 5,

    },
    {
        name: "GoHo",
        image: GoHo,
        singles: 24,
        doubles: 38,
        triples: 5,

    },
    {
        name: "Perkins Wilson",
        image: PiWi,
        singles: 24,
        doubles: 38,
        triples: 5,

    },
    {
        name: "Pierce",
        image: Pierce,
        singles: 24,
        doubles: 38,
        triples: 5,

    },
    {
        name: "Treworgy",
        image: Treworgy,
        singles: 24,
        doubles: 38,
        triples: 5,

    },
    {
        name: "Roberts",
        image: Roberts,
        singles: 24,
        doubles: 38,
        triples: 5,

    },
    {
        name: "Carol Swan-Daniel",
        image: Carol,
        singles: 24,
        doubles: 38,
        triples: 5,

    },
    {
        name: "Jane Powers",
        image: Jane,
        singles: 24,
        doubles: 38,
        triples: 5,

    },
    {
        name: "Paula Crane Lunder",
        image: Paula,
        singles: 24,
        doubles: 38,
        triples: 5,


    },
    {
        name: "Jacqueline Núñez",
        image: Jacqueline,
        singles: 24,
        doubles: 38,
        triples: 5,

    },
    {
        name: "Sturtevant",
        image: Sturtevant,
        singles: 24,
        doubles: 38,
        triples: 5,

    },
    {
        name: "Senior Apartments",
        image: Apts,
        singles: 24,
        doubles: 38,
        triples: 5,

    },

]
