import React, { useState, useEffect, useRef } from 'react'
import { DormList } from '../components/DormList';
import { Link } from 'react-router-dom';
import DormItem from '../components/DormItem';
import Campus from '../media/campus.png';
import '../styles/Dorms.css'

function Dorms() {

  const imgRef = useRef(null);
  const [areaCoords, setAreaCoords] = useState([]);

  const coordsList = [
    [765,187,810,265],
    [166,351,249,410],
    [110,500,185,532],
    [114,531,145,550],
    [149,155,233,255],
    [175,38,235,130],
    [142,534,171,564],
    [126,547,154,578],
    [433,180,480,200],
    [566,180,615,205],
    [370,59,445,142],
    [703,175,730,215],
    [662,183,719,205],
    [628,174,670,205],
    [705,244,725,273],
    [661,248,715,265],
    [630,241,655,272],
    [205,454,277,474],
    [202,464,243,496],
    [237,474,267,485],
    [268,479,291,505],
    [242,483,271,510],
    [330,140,365,159],
    [330,19,351,53],
    [310,54,334,90],
    [293,90,313,120],
    [313,114,330,149],
    [325,116,345,140],
    [656,117,713,159],
    [542,70,589,100],
    [552,93,597,118],
    [600,90,655,137],
    [451,65,500,110],
    [444,97,473,118],
    [529,109,585,163],
    [456,113,515,163]
  ];

  const dormNames = [
    "Roberts",
    "Dana",
    "Foss",
    "Foss",
    "Heights",
    "SeniorApartments",
    "Woodman",
    "Woodman",
    "Averill",
    "Johnson",
    "AMS",
    "GoHo",
    "Drummond",
    "Piper",
    "Treworgy",
    "Pierce",
    "PiWi",
    "MaryLow",
    "MaryLow",
    "MaryLow",
    "Coburn",
    "Coburn",
    "Taylor",
    "Williams",
    "Marriner",
    "Leonard",
    "Sturtevant",
    "Sturtevant",
    "Carol-Swan-Daniel-House",
    "Paula-Crane-Lunder-House",
    "Paula-Crane-Lunder-House",
    "Jane-Powers-House",
    "Jacqueline-Nunez-House",
    "Jacqueline-Nunez-House",
    "East",
    "West"
  ]
  
  function updateMapCoordinates() {
    if(imgRef.current) {
      var x_factor = imgRef.current.offsetWidth / 920;
      coordsList.forEach((item, index) => {
        const area_name = dormNames[index];
        console.log(area_name)
        const area = document.getElementById(area_name);
        if (area) { // Check if the area element exists to avoid null errors
          const coords = coordsList[index]; // Use the same index for dormNames and coordsList
          area.coords = coords.map(coord => Math.round(coord * x_factor)).join(',');
        }
      });
    }
    
  }

  window.addEventListener('resize', updateMapCoordinates);

  useEffect(() => {
    if (imgRef.current) {
      const newCoords = coordsList.map(coords => getCoords(coords));
      setAreaCoords(newCoords);
    }
  }, [imgRef.current]);

  function getCoords(coords){
    // want it so that when the image is loaded, 

    var x_factor = imgRef.current.clientWidth / 920;
    var y_factor = imgRef.current.clientHeight / 606;
    var x1 = Math.round(coords[0] * x_factor)
    var y1 = Math.round(coords[1] * y_factor)
    var x2 = Math.round(coords[2] * x_factor)
    var y2 = Math.round(coords[3] * y_factor)
    return `${x1},${y1},${x2},${y2}`;
  }

  const handleImageLoad = () => {
    console.log(imgRef.current.clientWidth);
    console.log(imgRef.current.clientHeight);
    // Trigger recalculation when image is loaded
    if (imgRef.current) {
      const newCoords = coordsList.map(coords => getCoords(coords));
      setAreaCoords(newCoords);
    }
  };


  return (
    
    <>
    <map name="campus">
        {areaCoords.map((coords, index) => (
          <Link to= {"/dorms/" + dormNames[index]}>
            <area key={index}
              id={dormNames[index]}
              shape="rect"
              coords={coords}
              //href={`/dorms/${dormNames[index]}`} // Ensure you have the dormLinks array defined or modify accordingly
              alt="Dorm"
            />
          </Link>
        ))}
      </map>
    <img src={Campus} useMap="#campus" className="responsive-map" ref={imgRef} onLoad={handleImageLoad} onResizeCapture={handleImageLoad}/>
    <div className = "dorms">
        <h1> Dorms </h1>
        <div className= "dormList">
            {DormList.map((dormItem, key) => {
                return (
                <DormItem
                key = {key}
                name={dormItem.name}
                image={dormItem.image}
                singles={dormItem.singles}
                doubles={dormItem.doubles}
                triples={dormItem.triples}
                dormPageUrl={dormItem.url}/>)
            })}

        </div>
      
    </div>
    </>
  );
}

export default Dorms

