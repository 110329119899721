export const MaryLowDormList = [
    {
        name: "Mary Low 115",
        dorm: "Mary Low",
        number: "115",
        sqft: 105,
        type: "single"
    },
    {
        name: "Mary Low 117",
        dorm: "Mary Low",
        number: "117",
        sqft: 165,
        type: "double"
    },
    {
        name: "Mary Low 121",
        dorm: "Mary Low",
        number: "151",
        sqft: 115,
        type: "single"
    },
    {
        name: "Mary Low 122",
        dorm: "Mary Low",
        number: "122",
        sqft: 122,
        type: "single"
    },
    {
        name: "Mary Low 123",
        dorm: "Mary Low",
        number: "123",
        sqft: 187,
        type: "double"
    },
    {
        name: "Mary Low 124",
        dorm: "Mary Low",
        number: "124",
        sqft: 203,
        type: "double"
    },
    {
        name: "Mary Low 126",
        dorm: "Mary Low",
        number: "126",
        sqft: 115,
        type: "single"
    },
    {
        name: "Mary Low 128",
        dorm: "Mary Low",
        number: "128",
        sqft: 132,
        type: "single"
    },
]