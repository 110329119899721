export const CoburnDormList = [
    {
        name: "Coburn 041",
        dorm: "Coburn",
        number: "041",
        sqft: 213,
        type: "double"
 
 
    },
    {
        name: "Coburn 042",
        dorm: "Coburn",
        number: "042",
        sqft: 142,
        type: "double"
 
 
    },
    {
        name: "Coburn 043",
        dorm: "Coburn",
        number: "043",
        sqft: 117,
        type: "single"
 
 
    },
    {
        name: "Coburn 044",
        dorm: "Coburn",
        number: "044",
        sqft: 120,
        type: "single"
 
 
    },
    {
        name: "Coburn 045",
        dorm: "Coburn",
        number: "045",
        sqft: 117,
        type: "single"
 
 
    },
    {
        name: "Coburn 046",
        dorm: "Coburn",
        number: "046",
        sqft: 125,
        type: "single"
 
 
    },
    {
        name: "Coburn 047",
        dorm: "Coburn",
        number: "047",
        sqft: 117,
        type: "single"
 
 
    },
    {
        name: "Coburn 049",
        dorm: "Coburn",
        number: "049",
        sqft: 117,
        type: "single"
 
 
    },
    {
        name: "Coburn 051",
        dorm: "Coburn",
        number: "051",
        sqft: 117,
        type: "single"
 
 
    },
    {
        name: "Coburn 053",
        dorm: "Coburn",
        number: "053",
        sqft: 126,
        type: "single"
 
 
    },
    {
        name: "Coburn 055",
        dorm: "Coburn",
        number: "055",
        sqft: 188,
        type: "double"
 
 
    },
    {
        name: "Coburn 057",
        dorm: "Coburn",
        number: "057",
        sqft: 169,
        type: "double"
 
 
    },
    {
        name: "Coburn 062",
        dorm: "Coburn",
        number: "062",
        sqft: 198,
        type: "double"
 
 
    },
    {
        name: "Coburn 064",
        dorm: "Coburn",
        number: "064",
        sqft: 136,
        type: "single"
 
 
    },    {
        name: "Coburn 066",
        dorm: "Coburn",
        number: "066",
        sqft: 125,
        type: "single"
 
 
    },
    {
        name: "Coburn 070",
        dorm: "Coburn",
        number: "070",
        sqft: 135,
        type: "single"
 
 
    },
    {
        name: "Coburn 141",
        dorm: "Coburn",
        number: "141",
        sqft: 213,
        type: "double"
 
 
    },
    {
        name: "Coburn 142",
        dorm: "Coburn",
        number: "142",
        sqft: 142,
        type: "single"
 
 
    },
    {
        name: "Coburn 143",
        dorm: "Coburn",
        number: "143",
        sqft: 117,
        type: "single"
 
 
    },
    {
        name: "Coburn 144",
        dorm: "Coburn",
        number: "144",
        sqft: 120,
        type: "single"
 
 
    },
    {
        name: "Coburn 145",
        dorm: "Coburn",
        number: "145",
        sqft: 117,
        type: "single"
 
 
    },
    {
        name: "Coburn 146",
        dorm: "Coburn",
        number: "146",
        sqft: 125,
        type: "single"
 
 
    },
    {
        name: "Coburn 147",
        dorm: "Coburn",
        number: "147",
        sqft: 117,
        type: "single"
 
 
    },
    {
        name: "Coburn 149",
        dorm: "Coburn",
        number: "149",
        sqft: 117,
        type: "single"
 
 
    },
    {
        name: "Coburn 151",
        dorm: "Coburn",
        number: "151",
        sqft: 117,
        type: "single"
 
 
    },
    {
        name: "Coburn 153",
        dorm: "Coburn",
        number: "153",
        sqft: 117,
        type: "single"
 
 
    },
    {
        name: "Coburn 162",
        dorm: "Coburn",
        number: "162",
        sqft: 199,
        type: "double"
 
 
    },
    {
        name: "Coburn 164",
        dorm: "Coburn",
        number: "164",
        sqft: 152,
        type: "double"
 
 
    },
    {
        name: "Coburn 166",
        dorm: "Coburn",
        number: "166",
        sqft: 268,
        type: "triple"
 
 
    },
    {
        name: "Coburn 169",
        dorm: "Coburn",
        number: "169",
        sqft: 196,
        type: "double"
 
 
    },
    {
        name: "Coburn 241",
        dorm: "Coburn",
        number: "241",
        sqft: 193,
        type: "double"
 
 
    },
    {
        name: "Coburn 242",
        dorm: "Coburn",
        number: "242",
        sqft: 127,
        type: "single"
 
 
    },
    {
        name: "Coburn 243",
        dorm: "Coburn",
        number: "243",
        sqft: 123,
        type: "single"
 
 
    },
    {
        name: "Coburn 244",
        dorm: "Coburn",
        number: "244",
        sqft: 126,
        type: "single"
 
 
    },
    {
        name: "Coburn 245",
        dorm: "Coburn",
        number: "245",
        sqft: 123,
        type: "single"
 
 
    },
    {
        name: "Coburn 246",
        dorm: "Coburn",
        number: "246",
        sqft: 126,
        type: "single"
 
 
    },
    {
        name: "Coburn 247",
        dorm: "Coburn",
        number: "247",
        sqft: 123,
        type: "single"
 
 
    },
    {
        name: "Coburn 248",
        dorm: "Coburn",
        number: "248",
        sqft: 127,
        type: "single"
 
 
    },
    {
        name: "Coburn 249",
        dorm: "Coburn",
        number: "249",
        sqft: 123,
        type: "single"
 
 
    },
    {
        name: "Coburn 251",
        dorm: "Coburn",
        number: "251",
        sqft: 123,
        type: "single"
 
 
    },
    {
        name: "Coburn 253",
        dorm: "Coburn",
        number: "253",
        sqft: 358,
        type: "triple"
 
 
    },
    {
        name: "Coburn 255",
        dorm: "Coburn",
        number: "255",
        sqft: 241,
        type: "triple"
 
 
    },
    {
        name: "Coburn 257",
        dorm: "Coburn",
        number: "257",
        sqft: 133,
        type: "single"
 
 
    },
    {
        name: "Coburn 259",
        dorm: "Coburn",
        number: "259",
        sqft: 133,
        type: "single"
 
 
    },
    {
        name: "Coburn 261",
        dorm: "Coburn",
        number: "261",
        sqft: 133,
        type: "single"
 
 
    },
    {
        name: "Coburn 262",
        dorm: "Coburn",
        number: "262",
        sqft: 211,
        type: "double"
 
 
    },
    {
        name: "Coburn 263",
        dorm: "Coburn",
        number: "263",
        sqft: 133,
        type: "single"
 
 
    },
    {
        name: "Coburn 264",
        dorm: "Coburn",
        number: "264",
        sqft: 200,
        type: "double"
 
 
    },
    {
        name: "Coburn 341",
        dorm: "Coburn",
        number: "341",
        sqft: 209,
        type: "double"
 
 
    },
    {
        name: "Coburn 342",
        dorm: "Coburn",
        number: "342",
        sqft: 123,
        type: "single"
 
 
    },
    {
        name: "Coburn 343",
        dorm: "Coburn",
        number: "343",
        sqft: 119,
        type: "single"
 
 
    },
    {
        name: "Coburn 344",
        dorm: "Coburn",
        number: "344",
        sqft: 123,
        type: "single"
 
 
    },
    {
        name: "Coburn 345",
        dorm: "Coburn",
        number: "345",
        sqft: 119,
        type: "single"
 
 
    },
    {
        name: "Coburn 346",
        dorm: "Coburn",
        number: "346",
        sqft: 121,
        type: "single"
 
 
    },
    {
        name: "Coburn 347",
        dorm: "Coburn",
        number: "347",
        sqft: 119,
        type: "single"
 
 
    },
    {
        name: "Coburn 348",
        dorm: "Coburn",
        number: "348",
        sqft: 124,
        type: "single"
 
 
    },
    {
        name: "Coburn 349",
        dorm: "Coburn",
        number: "349",
        sqft: 119,
        type: "single"
 
 
    },
    {
        name: "Coburn 351",
        dorm: "Coburn",
        number: "351",
        sqft: 119,
        type: "single"
 
 
    },
    {
        name: "Coburn 353",
        dorm: "Coburn",
        number: "353",
        sqft: 353,
        type: "triple"
 
 
    },
    {
        name: "Coburn 355",
        dorm: "Coburn",
        number: "355",
        sqft: 239,
        type: "double"
 
 
    },
    {
        name: "Coburn 357",
        dorm: "Coburn",
        number: "357",
        sqft: 126,
        type: "single"
 
 
    },
    {
        name: "Coburn 359",
        dorm: "Coburn",
        number: "359",
        sqft: 129,
        type: "single"
 
 
    },
    {
        name: "Coburn 361",
        dorm: "Coburn",
        number: "361",
        sqft: 136,
        type: "single"
 
 
    },
    {
        name: "Coburn 362",
        dorm: "Coburn",
        number: "362",
        sqft: 205,
        type: "double"
 
 
    },
    {
        name: "Coburn 363",
        dorm: "Coburn",
        number: "363",
        sqft: 123,
        type: "single"
 
 
    },
    {
        name: "Coburn 364",
        dorm: "Coburn",
        number: "364",
        sqft: 180,
        type: "double"
 
 
    },











]