import React from 'react';
import { Link } from 'react-router-dom';
import { FDormList } from '../../components/FDormList';
import DormRoom from '../../components/DormRoom'
import FossFloor2 from '../../media/foss_floor2.png';

import '../../styles/Foss.css';


function Foss() {
  return (
    <>
      <map name="fossfloor2">
        <area
          shape="rect"
          coords="0,0,250,250"
          href="/Foss/74"
          target="_blank"
          alt="HTML" />
      </map><img src={FossFloor2} useMap="#campus" className="responsive-map"/>
    <div className="dormRooms">
        <h1>Rooms</h1>
        <div className="dormList">
          {FDormList.map((dormRoom, index) => (
            <Link key={index} to={`/Foss/${dormRoom.number}`} className="button-link">
              <button className="room-button">
                <DormRoom
                  name={dormRoom.name}
                  dorm={dormRoom.dorm}
                  number={dormRoom.number}
                  sqft={dormRoom.sqft}
                  type={dormRoom.type} />
              </button>
            </Link>
          ))}
        </div>
      </div></>
  );
}

export default Foss;
