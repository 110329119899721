export const HeightsDormList = [
    {
        name: "Heights 101",
        dorm: "Heights",
        number: "101",
        sqft: 606,
        type: "suite (quad)"

    },
    {
        name: "Heights 102",
        dorm: "Heights",
        number: "102",
        sqft: 189,
        type: "double"

    },
    {
        name: "Heights 103",
        dorm: "Heights",
        number: "103",
        sqft: 189,
        type: "double"

    },
    {
        name: "Heights 104",
        dorm: "Heights",
        number: "103",
        sqft: 189,
        type: "double"

    },
    {
        name: "Heights 105",
        dorm: "Heights",
        number: "103",
        sqft: 189,
        type: "double"

    },
    {
        name: "Heights 106",
        dorm: "Heights",
        number: "103",
        sqft: 189,
        type: "double"

    },
    {
        name: "Heights 107A",
        dorm: "Heights",
        number: "107A",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 107B",
        dorm: "Heights",
        number: "107B",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 108A",
        dorm: "Heights",
        number: "108A",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 108B",
        dorm: "Heights",
        number: "108B",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 109A",
        dorm: "Heights",
        number: "109A",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 109B",
        dorm: "Heights",
        number: "109B",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 110",
        dorm: "Heights",
        number: "110",
        sqft: 668,
        type: "suite (quad)"

    },
    {
        name: "Heights 112",
        dorm: "Heights",
        number: "112",
        sqft: 241,
        type: "double"

    },
    {
        name: "Heights 117",
        dorm: "Heights",
        number: "117",
        sqft: 128,
        type: "single"

    },
    {
        name: "Heights 120",
        dorm: "Heights",
        number: "120",
        sqft: 606,
        type: "suite (quad)"

    },
    {
        name: "Heights 121",
        dorm: "Heights",
        number: "121",
        sqft: 672,
        type: "suite (quad)"

    },
    {
        name: "Heights 200",
        dorm: "Heights",
        number: "200",
        sqft: 605,
        type: "suite (quad)"

    },
    {
        name: "Heights 201",
        dorm: "Heights",
        number: "201",
        sqft: 606,
        type: "suite (quad)"

    },
    {
        name: "Heights 202A",
        dorm: "Heights",
        number: "202A",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 202B",
        dorm: "Heights",
        number: "202B",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 203",
        dorm: "Heights",
        number: "203",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 204",
        dorm: "Heights",
        number: "204",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 205",
        dorm: "Heights",
        number: "205",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 206A",
        dorm: "Heights",
        number: "206A",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 206B",
        dorm: "Heights",
        number: "206B",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 207A",
        dorm: "Heights",
        number: "207A",
        sqft: 119,
        type: "single"

    },
    {
        name: "Heights 207B",
        dorm: "Heights",
        number: "207B",
        sqft: 119,
        type: "single"

    },
    {
        name: "Heights 210",
        dorm: "Heights",
        number: "210",
        sqft: 657,
        type: "suite (quad)"

    },
    {
        name: "Heights 211",
        dorm: "Heights",
        number: "211",
        sqft: 657,
        type: "suite (quad)"

    },
    {
        name: "Heights 212A",
        dorm: "Heights",
        number: "212A",
        sqft: 113,
        type: "single"

    },
    {
        name: "Heights 212B",
        dorm: "Heights",
        number: "212B",
        sqft: 113,
        type: "single"

    },
    {
        name: "Heights 213",
        dorm: "Heights",
        number: "213",
        sqft: 125,
        type: "single"

    },
    {
        name: "Heights 214A",
        dorm: "Heights",
        number: "214A",
        sqft: 125,
        type: "single"

    },
    {
        name: "Heights 214B",
        dorm: "Heights",
        number: "214B",
        sqft: 125,
        type: "single"

    },
    {
        name: "Heights 215",
        dorm: "Heights",
        number: "215",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 216A",
        dorm: "Heights",
        number: "216A",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 216B",
        dorm: "Heights",
        number: "216B",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 217",
        dorm: "Heights",
        number: "217",
        sqft: 119,
        type: "single"

    },
    {
        name: "Heights 300",
        dorm: "Heights",
        number: "300",
        sqft: 711,
        type: "suite (quad)"

    },
    {
        name: "Heights 301",
        dorm: "Heights",
        number: "301",
        sqft: 685,
        type: "suite (quad)"

    },
    {
        name: "Heights 302A",
        dorm: "Heights",
        number: "302A",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 302B",
        dorm: "Heights",
        number: "302B",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 303A",
        dorm: "Heights",
        number: "303A",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 303B",
        dorm: "Heights",
        number: "303B",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 304A",
        dorm: "Heights",
        number: "304A",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 304B",
        dorm: "Heights",
        number: "304B",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 305",
        dorm: "Heights",
        number: "305",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 306A",
        dorm: "Heights",
        number: "306A",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 306B",
        dorm: "Heights",
        number: "306B",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 310",
        dorm: "Heights",
        number: "310",
        sqft: 652,
        type: "suite (quad)"

    },
    {
        name: "Heights 311",
        dorm: "Heights",
        number: "311",
        sqft: 653,
        type: "suite (quad)"

    },
    {
        name: "Heights 312A",
        dorm: "Heights",
        number: "312A",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 312B",
        dorm: "Heights",
        number: "312B",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 313A",
        dorm: "Heights",
        number: "313A",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 313B",
        dorm: "Heights",
        number: "313B",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 314A",
        dorm: "Heights",
        number: "314A",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 314B",
        dorm: "Heights",
        number: "314B",
        sqft: 122,
        type: "single"

    },
    {
        name: "Heights 315",
        dorm: "Heights",
        number: "315",
        sqft: 244,
        type: "double"

    },
    {
        name: "Heights 317",
        dorm: "Heights",
        number: "317",
        sqft: 118,
        type: "single"

    },




]